import plumber from './assets/img/plumber.jpg'
import cctv from './assets/img/cctv.jpg'
import electrician from './assets/img/electrician.png'
import carpenter from './assets/img/carpenter.png'
import website from './assets/img/website.png'
import android from './assets/img/android.jpg'
import event from './assets/img/event.jpg'
import fabrication from './assets/img/fabrication.jpg'
import ups from './assets/img/ups.png'
import tours from './assets/img/cars.jpeg'
import system from './assets/img/system.jpg'
import printer from './assets/img/printer.jpg'
import painting from './assets/img/painting.png'
import laptop from './assets/img/laptop.jpeg'
import lcd from './assets/img/lcd.jpg'
import mehindi from './assets/img/mehindi.jpg'
import photography from './assets/img/photography.jpeg'
import washing from './assets/img/washing.jpg'
import refrigerator from './assets/img/refrigerator.jpg'




export const links = [

    {
        name : "Home",
        path : '/'
    },
    {
        name : "Services",
        path : '/services'
    },
    {
        name : "Gallery",
        path : '/gallery'
    },
    {
        name : "ABS Training Center",
        path : '/trainingcenter'
    },
    {
        name:"ABS Pre School",
        path : "https://www.hellokids.co.in/branches"
    },
    {
        name : "About",
        path : '/about'
    },
    {
        name : "Contact",
        path : '/contact'
    }
]

export const services = [
    {
        id:1,
        title: "Carpenter",
        icon: <img src={carpenter} alt=""/>,
        info: "For me, woodworking encourages creativity. It Calms the savage beast!",
    },
    {
        id:2,
        title: "Plumber Service",
        icon: <img src={plumber} alt=""/>,
        info: "Acting is not an important job in the scheme of things Plumbing is",
    },
    {
        id:3,
        title: "CCTV Service",
        icon: <img src={cctv} alt=""/>,
        info: "Cameras are the best innovation and we make the best",
    },
    {
        id:4,
        title: "Electrician Service",
        icon: <img src={electrician} alt=""/>,
        info: "Electricity is really just organized lightning",
    },
]

export const serviceslist = [
    {
        id:1,
        title: "Carpenter",
        icon: <img src={carpenter} alt=""/>,
        info: "For me, woodworking encourages creativity. It Calms the savage beast!",
    },
    {
        id:2,
        title: "Plumber Service",
        icon: <img src={plumber} alt=""/>,
        info: "Acting is not an important job in the scheme of things Plumbing is",
    },
    {
        id:3,
        title: "CCTV Installation",
        icon: <img src={cctv} alt=""/>,
        info: "CCTV service with Safety at a reasonable cost.",
    },
    {
        id:4,
        title: "Electrician Service",
        icon: <img src={electrician} alt=""/>,
        info: "Electricity is really just organized lightning.",
    },
    {
        id:5,
        title: "Event Management",
        icon: <img src={event} alt=""/>,
        info:"Multilevel Multitasking Multiplied Multiple Times is Event Management.",
    },
    {
        id:6,
        title:"Fabrication",
        icon: <img src={fabrication} alt=""/>,
        info: "The fabrication of faith in yourself is what weaves you..!!",
    },
    {
        id:7,
        title:"Tours and Travels",
        icon: <img src={tours} alt=""/>,
        info:"We wander for distraction, but we travel for fulfilment.",
    },
    {
        id:8,
        title:"Photography",
        icon: <img src={photography} alt=""/>,
        info:"We take photos as a return ticket to a moment otherwise gone.",
    },
    {
        id:9,
        title:"System Service",
        icon: <img src={system} alt=""/>,
    },
    {
        id:10,
        title:"Printer Service",
        info:"It's Called a pen. It's like a printer hooked straight to my brain.",
        icon: <img src={printer} alt=""/>,
    },
    {
        id:11,
        title:"Website Design",
        info:"your website is the window of your business. keep it fresh, keep it exciting.",
        icon: <img src={website} alt=""/>,
    },
    {
        id:12,
        title:"Android Application",
        info:"Programing is not about typing, it's about thinking.",
        icon: <img src={android} alt=""/>,
    },
    {
        id:13,
        title:"Laptop Service",
        info:"I'm portable. I carry a laptop and a little recording studio on my back.",
        icon: <img src={laptop} alt=""/>,
    },
    {
        id:14,
        title:"Washing Machine",
        icon: <img src={washing} alt=""/>,
        info:"Animation is not the art of drawings that move but the art of movements that are drawn.",
    },
    {
        id:15,
        title:"Mehendi Design",
        icon: <img src={mehindi} alt=""/>,
        info:"Mehindi adorns the hands and life takes on a new color",
    },
    {
        id:16,
        title:"LCD LED TV Service",
        icon: <img src={lcd} alt=""/>,
    },
    {
        id:17,
        title:"UPS Service",
        icon: <img src={ups} alt=""/>,
    },
    {
        id:18,
        title:"Painting Service",
        icon: <img src={painting} alt=""/>,
    },
    {
        id:19,
        title:"Refrigerator Service",
        icon: <img src={refrigerator} alt=""/>,
    }
]

export const locations = [
    {
        id: 1,
        title: ". Saraswati Badavane"
    },
    {
        id: 2,
        title: ". Vidhyanagara"
    },
    {
        id: 3,
        title: ". Shivakumara Swamy Badavane"
    }, {
        id: 4,
        title: ". Jayanagara"
    },
    {
        id: 5,
        title: ". K T J Nagara"
    },
    {
        id: 6,
        title: ". Bhoomika Nagara"
    }, 
    {
        id: 7,
        title: ". Saraswati Badavane"
    }, 
    {
       id: 8,
       title: ". Nijalingappa Badavane"
    }, 
    {
        id: 9,
        title: ". Anjaneya Badavane"
    },
    {
        id: 10,
        title: ". Chigateri Badavane"
    },
    {
        id: 11,
        title: ". Dollars Colony"
    },
    {
        id: 12,
        title: ". Balaji Nagara"
    },
    {
        id: 13,
        title: ". Mahalaxmi Layout"
    },
    {
        id: 14,
        title: ". SS Layout"
    },
]

export const course_list = [
    {
        id:1,
        title:"Basic Computer",
        info: "Basic Hardware",
        d:"Basic Software",
        dd:"MS Office",
        ddd:"English Typing",
    },
    {
        id:2,
        title: "Soft Skills",
        info:"Communication Skills",
        d:"Interview Skills",
        dd:"Greeting Skills",
        ddd:"Life Skills & More",
    },
    {
        id:3,
        title:"Tally ERP & GST",
        info:"Tally ERP",
        dd:"GST",
    },
    {
        id:4,
        title:"Java",
        info:"Core Java",
        d:"Advance Java",
        dd:"Web Development",
    },
    {
        id:5,
        title:"Microsoft Azure",
        info:"Hardware (H+)",
        d:"Networking (N+)",
        dd:"CCNA",
        ddd:"MCSA",
        dddd:"Azure",
    },
]
