import {useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import './Navbar.css'
import Logo from '../assets/img/logo.png'
import {links} from '../data'
import {GoThreeBars} from 'react-icons/go'
import {MdOutlineClose} from 'react-icons/md'

const Navbar = () => {
    const [isNavShowing, setIsNavShowing] = useState(false);

  return (
    <nav>
        <div className="container nav_container">
            <Link to='/' className='logo'>
                <h1 className='title'>ABS SERVICE</h1> 
            <img src={Logo} alt="Logo"/>
            </Link>
            {/* <ul className={`nav_links ${isNavShowing ? 'show_nav' : 'hide_nav'}`}>
                {
                    links.map(({name,path},index) => {
                        return(
                            <li key={index}>
                                <NavLink to={path} className={({isActive}) => isActive ? 'active-nav' : ''}
                                onClick={() => setIsNavShowing(prev => !prev)}>{name}</NavLink>
                            </li>
                        )
                    })
                }
            </ul> */}
             <ul className={`nav_links ${isNavShowing ? 'show_nav' : 'hide_nav'}`}>
      {links.map(({ name, path }, index) => (
        <li key={index}>
          {/* Check if the link is an external link */}
          {path.startsWith('https') ? (
            // External link: Use <a> with target="_blank"
            <a
              href={path}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setIsNavShowing(prev => !prev)}
            >
              {name}
            </a>
          ) : (
            // Internal link: Use NavLink for routing
            <NavLink
              to={path}
              className={({ isActive }) => (isActive ? 'active-nav' : '')}
              onClick={() => setIsNavShowing(prev => !prev)}
            >
              {name}
            </NavLink>
          )}
        </li>
      ))}
    </ul>
            <button className='nav_toggle-btn' onClick={() => setIsNavShowing(prev => !prev )}>
                {
                    isNavShowing ? <MdOutlineClose/> : <GoThreeBars/>
                }
            </button>
        </div>

    </nav>
  )
}

export default Navbar