import Footer from "../../components/Footer";
import MainHeader from "../../components/MainHeader"
import Ourlocation from "../../components/Ourlocation";
import OurServices from '../../components/OurServices'
import bank from '../../assets/img/bank.jpeg'
import mahal from '../../assets/img/mahal.jpeg'
import finance from '../../assets/img/finance.jpeg'
import cvd from '../../assets/img/cvd.jpeg'
import './home.css';


const Home = () => {
  return (
    <>
    <MainHeader/>
    <OurServices/>
    <Ourlocation/>
    <h1 className="htitle">We worked for</h1>
        <div className="wimgs">
          <img src={bank} alt="" className="bank"/>
          <img src={mahal} alt="" className="mahal"/>
          <img src={finance} alt="" className="finance"/>
          <img src={cvd} alt="" className="cvd"/>
        </div>
    <h5 className="btext">ABS SERVICE | ABS TRAINING CENTER</h5>
    <Footer/>
    </>
  )
}

export default Home