import {MdMyLocation} from 'react-icons/md'
import {locations} from '../data'

const Ourlocation = () => {
  return (
    <div className="location">
        <h2>{<MdMyLocation/>}We Provide Our Services in</h2>
        <div className="areas">
            <ul className='location_areas'>
                {
                    locations.map(({id,title},index) => {
                        return(
                            <li key={index}>
                                <h3>{title}</h3>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    </div>
  )
}

export default Ourlocation