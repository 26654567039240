import Footer from "../../components/Footer"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Img1 from "../../assets/img/34.jpg"
import Img2 from "../../assets/img/33.jpg"
import Img4 from "../../assets/img/12.jpg"
import Img5 from "../../assets/img/13.jpg"
import Img6 from "../../assets/img/14.jpg"
import Img7 from "../../assets/img/69.jpg"
import Img8 from "../../assets/img/2.jpg"
import Img9 from "../../assets/img/8.jpg"
import Img10 from "../../assets/img/1.jpg"
import Img11 from "../../assets/img/45.jpg"
import Img12 from "../../assets/img/37.jpg"
import Img13 from "../../assets/img/20.jpg"
import Img14 from "../../assets/img/17.jpg"
import Img15 from "../../assets/img/80.jpg"
import Img16 from "../../assets/img/19.jpg"
import Img17 from "../../assets/img/21.jpg"
import Img18 from "../../assets/img/15.jpg"
import Img19 from "../../assets/img/23.jpg"
import Img20 from "../../assets/img/24.jpg"
import Img21 from "../../assets/img/25.jpg"
import Img22 from "../../assets/img/26.jpg"
import Img23 from "../../assets/img/2.jpg"
import Img24 from "../../assets/img/27.jpg"
import Img25 from "../../assets/img/28.jpg"
import Img26 from "../../assets/img/29.jpg"
import Img27 from "../../assets/img/30.jpg"
import Img28 from "../../assets/img/53.jpg"
import Img29 from "../../assets/img/87.jpg"
import Img30 from "../../assets/img/35.jpg"
import Img31 from "../../assets/img/86.jpg"
import Img32 from "../../assets/img/37.jpg"
import Img33 from "../../assets/img/58.jpg"
import Img35 from "../../assets/img/40.jpg"
import Img36 from "../../assets/img/42.jpg"
import Img37 from "../../assets/img/50.jpg"
import Img38 from "../../assets/img/44.jpg"
import Img40 from "../../assets/img/46.jpg"
import Img42 from "../../assets/img/59.jpg"
import Img44 from "../../assets/img/82.jpg"
import bank from '../../assets/img/bank.jpeg'
import mahal from '../../assets/img/mahal.jpeg'
import finance from '../../assets/img/finance.jpeg'
import cvd from '../../assets/img/cvd.jpeg'
import './gallery.css'

const Gallery = () => {

  let data = [
    {
      id:1,
      imgSrc: Img1,
    },
    {
      id:2,
      imgSrc: Img2,
    },
    {
      id:4,
      imgSrc: Img4,
    },
    {
      id:5,
      imgSrc: Img5,
    },
    {
      id:6,
      imgSrc: Img6,
    },
    {
      id:7,
      imgSrc: Img7,
    },
    {
      id:8,
      imgSrc: Img8,
    },
    {
      id:9,
      imgSrc: Img9,
    },
    {
      id:10,
      imgSrc: Img10,
    },
    {
      id:11,
      imgSrc: Img11,
    },
    {
      id:12,
      imgSrc: Img12,
    },
    {
      id:13,
      imgSrc: Img13,
    },
    {
      id:14,
      imgSrc: Img14,
    },
    {
      id:15,
      imgSrc: Img15,
    },
    {
      id:16,
      imgSrc: Img16,
    },
    {
      id:17,
      imgSrc: Img17,
    },
    {
      id:18,
      imgSrc: Img18,
    },
    {
      id:19,
      imgSrc: Img19,
    },
    {
      id:20,
      imgSrc: Img20,
    },
    {
      id:21,
      imgSrc: Img21,
    },
    {
      id:22,
      imgSrc: Img22,
    },
    {
      id:23,
      imgSrc: Img23,
    },
    {
      id:24,
      imgSrc: Img24,
    },
    {
      id:25,
      imgSrc: Img25,
    },
    {
      id:26,
      imgSrc: Img26,
    },
    {
      id:27,
      imgSrc: Img27,
    },
    {
      id:28,
      imgSrc: Img28,
    },
    {
      id:29,
      imgSrc: Img29,
    },
    {
      id:30,
      imgSrc: Img30,
    },
    {
      id:31,
      imgSrc: Img31,
    },
    {
      id:32,
      imgSrc: Img32,
    },
    {
      id:33,
      imgSrc: Img33,
    },
    {
      id:35,
      imgSrc: Img35,
    },
    {
      id:36,
      imgSrc: Img36,
    },
    {
      id:37,
      imgSrc: Img37,
    },
    {
      id:38,
      imgSrc: Img38,
    },
    {
      id:40,
      imgSrc: Img40,
    },
    {
      id:42,
      imgSrc:Img42,
    },
    {
      id:44,
      imgSrc:Img44,
    }
  ]

  return (
    <div>
       <div className="gallery_container">
        <div className="gallerytitle">
          <h1>Gallery</h1>
        </div>
        <div className="gallery_grid">
          {data.map((item, index)=>{
            return(
              <div className="pics" key={index}>
                <LazyLoadImage src={item.imgSrc}  style={{width: '100%'}} alt="gallery" effect="blur" placeholderSrc={item.imgSrc}/>
              </div>
            )
          })}
        </div>
      </div>
      <h1 className="wtitle">We worked for </h1>
      <div className="wimgs">
          <img src={bank} alt="" className="bank"/>
          <img src={mahal} alt="" className="mahal"/>
          <img src={finance} alt="" className="finance"/>
          <img src={cvd} alt="" className="cvd"/>
        </div>
      <h5 className="btext">ABS SERVICE | ABS TRAINING CENTER</h5>
      <Footer/>
    </div>
  )
}

export default Gallery