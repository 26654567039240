import Footer from "../../components/Footer"
import './about.css'
import bank from '../../assets/img/bank.jpeg'
import mahal from '../../assets/img/mahal.jpeg'
import finance from '../../assets/img/finance.jpeg'
import cvd from '../../assets/img/cvd.jpeg'

const About = () => {
  return (
    <div className="about_container">
      <div className="abouttitle">
        <h1>About US</h1>
      </div>
      <div className="about_story">
        <div className="aboutus">
          <p className="story">One of the greatest service providers in Davanagere is ABS Service. Launched in 2020, ABS Service is currently active in more than 20 Davanagere neighbourhoods. The platform makes it easy for customers to schedule reputable and high-quality services such as plumbing, electrical work, carpentry, CCTV camera installation, fabrication, aluminium partition, washing machine service, refrigerator service, system service, laptop service, Upvc windows and doors, UPS sales & service, solar water heater, gas geyser, LCD & LED TV service, balloon decoration, event management, DTH installation and services, and more that are conveniently provided at home by qualified specialists.</p>
        </div>
        <div className="doit">
          <h1>How we do it?</h1>
          <p className="howwe">The platform offered by ABS Service enables knowledgeable and experienced individuals to interact with customers looking for particular services.
          Once a user is on the platform, our matching system finds professionals who are both accessible and most compatible with their needs.</p>
        </div>
        <div className="mission">
          <h1>Our Mission</h1>
          <p className="mtitle">Our Mission is to empower millions of service professionals by delivering services at home in a way that has never been experienced before.</p>
        </div>
        <div className="vision">
          <h1>Our Vision</h1>
          <p className="vtitle">ABS SERVICE is a service-based business that offers customers and clients the best response to their needs or a service to another business.</p>
        </div>
      </div>
      <h1 className="abttitle">We worked for</h1>
      <div className="wimgs">
          <img src={bank} alt="" className="bank"/>
          <img src={mahal} alt="" className="mahal"/>
          <img src={finance} alt="" className="finance"/>
          <img src={cvd} alt="" className="cvd"/>
        </div>
      <h5 className="btext">ABS SERVICE | ABS TRAINING CENTER</h5>
      <Footer/>
    </div>
  )
}

export default About