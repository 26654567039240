import banner from "../../assets/img/coverpht.jpg"
import './services.css'
import Footer from "../../components/Footer"
import { serviceslist } from "../../data"
import Typewriter from 'typewriter-effect'
import ServiceCard from '../../UI/ServiceCard'
import {BsTelephoneOutbound} from 'react-icons/bs'
import bank from '../../assets/img/bank.jpeg'
import mahal from '../../assets/img/mahal.jpeg'
import finance from '../../assets/img/finance.jpeg'
import cvd from '../../assets/img/cvd.jpeg'

const Services = () => {
  return (
    <div className="service_container">
      <div className="banner">
        <img src={banner} alt="Our Services"/>
      </div>
      <div className="servtitle">
        <h1><Typewriter
        onInit={(typewriter) => {
          typewriter
          .typeString("Our Serivces")
          .pauseFor(2000)
          .deleteAll()
          .typeString("Our Services")
          .pauseFor(1300)
          .deleteAll()
          .typeString("Our Services")
          .start();
        }}/></h1>
      </div>
      <div className="servicelist_container">
        <div className="servicelist">
        {
          serviceslist.map(({id,icon,title,info,path}) => {
            return (
              <ServiceCard className="services_card" key={id}>
                <span className='service_icon'>{icon}</span>
                <h4>{title}</h4>
                <a href="tel:+91 7026127117" className="servicebtn">Book Service  <BsTelephoneOutbound/></a> 
              </ServiceCard>
            )
          })
        }
        </div>
        <h1 className="wtitle">We worked for</h1>
        <div className="wimgs">
          <img src={bank} alt="" className="bank"/>
          <img src={mahal} alt="" className="mahal"/>
          <img src={finance} alt="" className="finance"/>
          <img src={cvd} alt="" className="cvd"/>
        </div>
      </div>
      <h5 className="btext">ABS SERVICE | ABS TRAINING CENTER</h5>
      <Footer/>
    </div>
  )
}

export default Services