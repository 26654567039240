import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Navbar  from './components/Navbar'
import Home from './pages/home/Home'
import Services from './pages/services/Services'
import Gallery from './pages/gallery/Gallery'
import TrainingCenter from './pages/trainingcenter/Trainingcenter'
import About from './pages/about/About'
import Contact from './pages/contact/Contact'
import NotFound from './pages/NotFound/Notfound'

const App = () => {
  return (
    <BrowserRouter>
        <Navbar/>
        <Routes>
            <Route index element={<Home/>}/>
            <Route path='services' element={<Services/>}/>
            <Route path='gallery' element={<Gallery/>}/>
            <Route path='trainingcenter' element={<TrainingCenter/>}/>
            <Route path='about' element={<About/>}/>
            <Route path='contact' element={<Contact/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    </BrowserRouter>
  )
}

export default App