import contactimage from "../../assets/img/contact-img.svg"
import Footer from "../../components/Footer"
import './contact.css'
import {BsFillTelephoneFill} from 'react-icons/bs'
import { AiFillMail } from "react-icons/ai"
import bank from '../../assets/img/bank.jpeg'
import mahal from '../../assets/img/mahal.jpeg'
import finance from '../../assets/img/finance.jpeg'
import cvd from '../../assets/img/cvd.jpeg'
import { LazyLoadImage } from "react-lazy-load-image-component"

const Contact = () => {
  return (
    <div className="contact_container">
      <h1 className="ctitle">Contact Us</h1>
      <div className="contact">
        <LazyLoadImage src={contactimage} alt="" className="contimage"/>
        <h1 className="mail"> ABS SERVICE <br /> <AiFillMail/> absservices.offiial@gmail.com </h1>
         <h2 className="tphone"><BsFillTelephoneFill/> 70261-27117 </h2>
         <h2 className="tellphone"><BsFillTelephoneFill/> 08192-295274 </h2>
         <h1 className="trmail"> ABS TRAINING CENTER <br /> <AiFillMail/> abstrainingcenterdvg2021@gmail.com </h1>
        <h2 className="phone"> <BsFillTelephoneFill/> 94824-73482 </h2>
        <p className="caddress">
        ABS SERVICE
        Door No 2033/3 , 1st Floor , 1st Cross 
        Vidyanagar Circle , Behind Polar Bear , Vidhyanagara
        Davanagere , Karnataka. PIN Code: 577004
        </p>
      </div>
      <h1 className="contact_title">We worked for</h1>
      <div className="wimgs">
          <img src={bank} alt="" className="bank"/>
          <img src={mahal} alt="" className="mahal"/>
          <img src={finance} alt="" className="finance"/>
          <img src={cvd} alt="" className="cvd"/>
        </div>
      <h5 className="btext">ABS SERVICE | ABS TRAINING CENTER</h5>
    <Footer/>
    </div>
  )
}

export default Contact