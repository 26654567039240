import React from 'react'

const ServiceCard = ({className, children}) => {
    return (
      <article className={`services_card ${className}`}>
          {children}
      </article>
    )
  }

export default ServiceCard