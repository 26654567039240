import {FcServices} from 'react-icons/fc'
import SectionServices from './SectionServices'
import {services} from '../data'
import Card from '../UI/Card'
import {Link} from 'react-router-dom'
import {AiFillCaretRight} from 'react-icons/ai'
import {BsTelephoneOutbound} from 'react-icons/bs'

const OurServices = () => {
  return (
    <section className='services'>
      <div className='container services_container'>
        <SectionServices icon={<FcServices/>} title="Our Service"/>
      <div className="services_wrapper">
        {
          services.map(({id,icon,title,info,path}) => {
            return (
              <Card className="services_services" key={id}>
                <span className='icon'>{icon}</span>
                <h4>{title}</h4>
                <a href="tel:+91 7026127117" className="btnbook">Book Service  <BsTelephoneOutbound/></a> 
              </Card>
            )
          })
        }
      </div>
      <div>
        <Link to='/services' className="btnservices">More Services <AiFillCaretRight/></Link> 
      </div>
      </div>
    </section>
  )
}

export default OurServices