import img1 from '../assets/img/72.jpg'
import img2 from '../assets/img/73.jpg'
import img3 from '../assets/img/teaching.jpeg'
import img4 from '../assets/img/75.jpg'
import img5 from '../assets/img/76.jpg'
import img6 from '../assets/img/77.jpg'
import img7 from '../assets/img/91.jpg'
import img8 from '../assets/img/92.jpg'
import { LazyLoadImage } from "react-lazy-load-image-component"
import 'react-html5video/dist/styles.css'
import './tgallery.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";



const Tgallery = () => {

  return (
    <>
    <div className="main-video-player">
      <Swiper navigation={true} modules={[Navigation]} className="video_swiper">
         <SwiperSlide>
        <iframe src="https://www.youtube.com/embed/q38iNRfp1fc" title="ABS TRAINING CENTER Davanagere.                            Contact : 9482473482 , 7026127117" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className='video'></iframe>
        </SwiperSlide>
        <SwiperSlide>
          <iframe src="https://www.youtube.com/embed/8LEwq7Ko-EM" title="ABS TRAINING CENTER Davanagere.                            Contact : 9482473482 , 7026127117" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className='video'></iframe>
        </SwiperSlide>
      </Swiper>
    </div>
    <div className='main-gallery-wrapper'>
    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
        <LazyLoadImage src={img3} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
        <LazyLoadImage src={img4} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
        <LazyLoadImage src={img5} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
        <LazyLoadImage src={img6} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
        <LazyLoadImage src={img7} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
        <LazyLoadImage src={img8} alt=""/>
        </SwiperSlide>
      </Swiper>
    </div>
    </>
  )
}

export default Tgallery