import image from '../assets/img/character.jpeg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const MainHeader = () => {
  return (
    <header className='main_header'>
        <div className="container main_header-container">
            <div className="main_header-left">
               <h1>ABS Service</h1> 
               <h3>Extending - Services</h3>
               <p>
               "We offer superior goods and unrivalled services that combined give our customers a premium value"
               </p>
            </div>
            <div className="main_header-right">
                <div className="main_header-image">
                    <LazyLoadImage src={image} alt=""/>
                </div>
            </div>
        </div>
    </header>
  )
}

export default MainHeader