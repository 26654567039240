import './trainingcenter.css'
import Tgallery from '../../components/Tgallery'
import Footer from '../../components/Footer'
import ServiceCard from '../../UI/ServiceCard'
import { LazyLoadImage } from "react-lazy-load-image-component"
import { course_list } from "../../data"
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from '../../assets/img/review1.jpeg'
import img2 from '../../assets/img/review2.jpeg'
import img3 from '../../assets/img/review3.jpeg'
import img4 from '../../assets/img/review4.jpeg'
import img5 from '../../assets/img/review5.jpeg'
import img6 from '../../assets/img/review6.jpeg'
import img7 from '../../assets/img/review7.jpeg'
import ReactWhatsapp from 'react-whatsapp'
import {AiOutlineWhatsApp} from 'react-icons/ai'
import bosch from '../../assets/img/bosch.jpeg'
import boschbanner from '../../assets/img/boschbanner.jpeg'


const Trainingcenter = () => {

  return (
    <div>
    <div className="center_container">
      <h1 className='ttitle'>ABS TRAINING CENTER</h1>
        <p className='aboutlines'>One of the best IT Training Companies in Davanagere was founded in 2021 and is called ABS Training Center. We provide courses in both offline and online formats, including Basic Computer & Soft Skills, Tally, Java, Microsoft Azure, and others. <br /><br />
        "We can gladly state that we are an authorised Bosch - BRIDGE Training Center." <br /> <br />
        Our mission is to use education to make the world more just and prosperous. <br /> <br />
        By making IT Education easily accessible on a global scale, we aspire to achieve this.</p>
    </div>
    <Tgallery/>
    <div className="course_container">
      <h1>Course We Offer</h1>
      <div className="courselist">
          {
            course_list.map(({id,title,info,d,dd,ddd,dddd}) => {
              return (
                <ServiceCard className="course_Card" key={id}>
                  <h4>{title}</h4>
                  <li className='list'>{info}</li>
                  <li className='list'>{d}</li>
                  <li className='list'>{dd}</li>
                  <li className='list'>{ddd}</li>
                  <li className='list'>{dddd}</li>
                  <ReactWhatsapp number='+91 9482473482' message="I am , Interested" className="enquirybtn"> Enquiry Now <AiOutlineWhatsApp/> </ReactWhatsapp>
                </ServiceCard>
              )
            })
          }
        </div>
    </div>
    <div className='main-review-wrapper'>
      <h1> TESTIMONIALS</h1>
      <div className="reviewlist">
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide>
            <img src={img1} alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={img2} alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={img3} alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={img4} alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={img5} alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={img6} alt=""/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={img7} alt=""/>
          </SwiperSlide>
        </Swiper>
      </div>
      <h2 className="atitle">We Associated with</h2>
        <div className="bimgs">
          <LazyLoadImage src={bosch} alt="" className="bosch"/>
          <LazyLoadImage src={boschbanner} alt="" className="boschbanner"/>
        </div>
      <h5 className="btext">ABS SERVICE | ABS TRAINING CENTER </h5>
    <Footer/>
    </div>
    </div>
  )
}

export default Trainingcenter