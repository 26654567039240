import {Link} from 'react-router-dom'
import Logo from '../assets/img/logo.png'
import {FaFacebookF} from 'react-icons/fa'
import {AiFillInstagram} from 'react-icons/ai'
import { AiOutlineTwitter } from 'react-icons/ai'
import { AiFillMail } from "react-icons/ai"


const Footer = () => {
  return (
    <footer>
        <div className='container footer_container'>
            <article>
                <Link to='/'>
                    <img src={Logo} alt="logo" className='flogo'/>
                </Link>
                <h6 className='group'>ABS GROUP </h6>
                <h4 className='gname'>ABS SERVICE | ABS TRAINING CENTER</h4>
                <p className='value'>
                "We offer superior goods and unrivalled services that combined give our customers a premium value"
                </p>
                <div className="footer_social">
                    <a href="https://facebook.com/abs.davanaclsgere" target="_blank" rel='noreferrer noopener'><FaFacebookF/></a>
                    <a href="https://www.instagram.com/invites/contact/?i=1gs1vq9awcevm&utm_content=gp9xq0f" target="_blank" rel='noreferrer noopener'><AiFillInstagram/></a>
                    <a href=" https://twitter.com/ABSService2?t=7N12YOwWAAx0AzdIlHGZDQ&s=08" target="_blank" rel='noreferrer noopener'><AiOutlineTwitter/></a>
                    
                </div>
            </article>
            <article>
                <Link to="/services" className='sfont'>Services</Link>
                <Link to="/gallery" className='sfont'>Gallery</Link>
                <Link to="/trainingcenter" className='sfont'>Training Center</Link>
                <Link to="/about" className='sfont'>About</Link>
                <Link to="/contact" className='sfont'>Contact</Link>
            </article>
            <article>
                <h4>Get in Touch</h4>
               <a href="mailto:absservices.official@gmail.com" className='imail'><AiFillMail/>  absservices.official@gmail.com</a>
            </article>
            <article className='location_container'>
                <h4>Location</h4>
                <p className='address'>
                    Door No 2033/3 , 1st Floor , 1st Cross Vidyanagar Circle,Behind Polar Bear,Vidhyanagara Davanagere , Karnataka. PIN-Code:577004
                </p>
            </article>
            <div class="mapouter">
                <iframe src="https://maps.google.com/maps?q=ABS%20SERVICE%20DAVANAGERE,%20Keshav%20Enclave%20Shivakumara%20Swamy%20Badavane%201st%20Stage%20St%20John's%20school,%20road,%20behind%20Maganuru%20Basappa%20Petrol%20Bunk,%20Davanagere,%20Karnataka%20577004&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>
        </div>
        <div className="footer_copyright">
                <small>2022 ABS SERVICES &copy; All Rights Reserved</small>
        </div>
    </footer>
  )
}

export default Footer